<template>
    <div class="wrap">
        <s-header :name="'订单详情'"></s-header>
        <div class="addressWrap">
            <div>
                <p>
                    <span>{{ details.receiver_name }}</span>
                    <span>{{ details.receiver_mobile }}</span>
                </p>
                <p style="margin-top: 10px;color: #999">{{ details.receiver_region_name }} {{
                    details.receiver_address
                    }}</p>
            </div>
        </div>
        <div v-for="(item,index) in details.product" :key="index" style="padding-bottom: 50px">
            <div class="goods">
                <p class="title">
                    <span style="font-size: 16px;color: #333">{{ item.product_name }}</span>
                    <span style="font-size: 16px;color: red">{{ item.price }}</span>
                </p>
                <div class="flex">
                    <img style="width: 80px" :src="item.product_picture"/>
                    <span>共{{ item.num }}件</span>
                </div>
            </div>
            <div class="goods">
                <p class="title">
                    <span style="font-size: 16px;color: #333">商品金额</span>
                    <span style="color: #333;font-weight: bold">¥<span style="font-size: 16px">{{details.product_money}}</span></span>
                </p>
                <p class="flex">
                    <span style="font-size: 16px;color: #333">配送费</span>
                    <span style="color: #333;font-weight: bold">¥<span
                            style="font-size: 16px">{{ details.shipping_money }}</span></span>
                </p>
                <p class="flex">
                    <span style="font-size: 16px;color: #333">优惠金额</span>
                    <span style="color: #333;font-weight: bold">-¥<span
                            style="font-size: 16px">{{ details.coupon_money }}</span></span>
                </p>
                <p class="flex">
                    <span style="font-size: 16px;color: #333">赠送积分</span>
                    <span style="color: #333;font-weight: bold">{{ details.give_point }}</span>
                </p>
                <p class="flex">
                    <span style="font-size: 16px;color: #333">实付金额</span>
                    <span style="color: #333;font-weight: bold">¥<span
                            style="font-size: 16px">{{ details.pay_money }}</span></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import sHeader from '@/components/SimpleHeader'
    import {onMounted, reactive, toRefs} from "vue";
    import {useRouter} from "vue-router";
    import { orderDetails } from '@/service/order'

    export default {
        components: {
            sHeader
        },
        name: "OrderDetails",
        setup() {
            const router = useRouter()
            const state = reactive({
                // 订单详情
                details: {}
            })
            onMounted(async () => {
                const {data} = await orderDetails({
                    id: router.currentRoute.value.query.id
                })
                state.details = data;
            })

            return {
                ...toRefs(state)
            }
        }
    }
</script>

<style scoped lang="less">
    /* 优惠券列表 */
    .coupon-item {
        display: flex;
        flex-direction: column;
        margin: 10px 14px;
        padding: 10px;

        .con {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 10px;
            background: #fff;

            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                width: 100%;
                height: 0;
                border-bottom: 1px dashed #f3f3f3;
                transform: scaleY(50%);
            }
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            overflow: hidden;
            height: 100px;
        }

        .title {
            font-size: 16px;
            color: #303133;
            margin-bottom: 10px;
        }

        .time {
            font-size: 16px;
            color: #909399;
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #606266;
            height: 100px;
        }

        .price {
            font-size: 16px;
            color: #fa436a;

            &:before {
                content: '￥';
                font-size: 16px;
            }
        }

        .price-discount {
            font-size: 16px;
            color: #fa436a;
        }

        .tips {
            font-size: 16px;
            color: #909399;
            line-height: 30px;
            padding-left: 30px;
        }

        .circle {
            position: absolute;
            left: -6px;
            bottom: -10px;
            z-index: 10;
            width: 20px;
            height: 20px;
            background: #f3f3f3;
            border-radius: 100px;

            &.r {
                left: auto;
                right: -6px;
            }
        }

    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wrap {
        background: #eee;

        .addressWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px;
            padding: 20px;
            background: #fff;
            font-size: 16px;
            border-radius: 8px;

            p {
                margin: 0;
            }
        }
    }

    ::v-deep .van-cell {
        width: auto;
        margin: 10px 10px 0 10px;
        background: #fff;
        border-radius: 12px;
        font-size: 20px;
        color: #333;
        font-weight: bold;
        padding: 20px 10px;
    }

    .goods {
        margin: 20px 10px;
        background: #fff;
        padding: 20px;
        border-radius: 12px;

        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    }

    .goodsTip {
        margin: 20px 10px;
        background: #fff;
        padding: 20px;
        border-radius: 12px;
        font-size: 16px;
        color: #999;
    }

    .pay {
        position: fixed;
        bottom: 0;
        border-top: 1px solid #ccc;

        ::v-deep .van-submit-bar__bar {
            justify-content: space-between;
        }

        ::v-deep .van-submit-bar__text {
            flex: none;
        }
    }
</style>
